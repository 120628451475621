<template>
  <el-dialog
    title="岗位导出数据"
    :visible.sync="exportVisible"
    width="700px"
    :close-on-click-modal="false"
    :before-close="closeDialog"
    v-ismove>
      <el-form :model="exportForm" :rules="formRules" ref="exportForm" label-width="120px" size="small">
        <el-form-item label="选择系统：" prop="usId">
          <el-select size="small" v-model="exportForm.usId" placeholder="请选择" clearable filterable>
            <el-option v-for="(system,index) in exportSystemList" :key="index" :label="system.usName" :value="system.usId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button type="primary" size="small" @click="exportData()">确 定</el-button>
      </div>
    </el-dialog>
</template>

<script>
import { getPostSystemList, editIdPostData } from '@/api/post'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import { getSystemToken } from '@/utils/systemid'
// import { requiredC } from '@/utils/validate'

export default {
  props: {
    exportVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    upId: {
      type: Number,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      exportSystemList: [],
      exportForm: {
        upId: '',
        usId: null,
        ucId: ''
      },
      formRules: {
        // usId: [requiredC]
        usId: { required: true, message: '必选项', trigger: 'change' }
      }
    }
  },
  watch: {
    exportVisible: function() {
      if (this.exportVisible) {
        this.getExportData()
      }
    }
  },
  methods: {
    getExportData() {
      this.exportForm.usId = null
      // 获取导出需要的参数，根据Id查询岗位信息
      editIdPostData(this.upId)
        .then(response => {
          this.exportForm.ucId = response.data.ucId
          this.exportForm.upId = response.data.upId
        })
        .catch(err => {
          console.log(err)
        })
      // 获取职位信息，超管可以多公司操作
      getPostSystemList(this.upId)
        .then(response => {
          if (response.code === '000000' && response.data) {
            this.exportSystemList = response.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    exportData() {
      this.$refs.exportForm.validate((valid) => {
        if (valid) {
          const token = getToken()
          this.userIp = Cookies.get('userIp')
          this.systemCode = getSystemToken()
          window.location.href =
                          process.env.VUE_APP_BASE_API + '/position/get/rights/csv?systemCode=' +
                          this.systemCode +
                          '&hostIp=' +
                          this.userIp +
                          '&upId=' +
                          this.exportForm.upId +
                          '&usId=' +
                          this.exportForm.usId +
                          '&ucId=' +
                          this.exportForm.ucId +
                          '&token=' +
                          token
          this.closeDialog()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style>

</style>
