import request from '@/utils/request'

// 权限管理接口

// 获取系统列表
export function getAllSystem() {
  return request({
    url: '/userRight/get/system-list',
    method: 'get'
  })
}

// 获取所有模块
export function getAllModule(usId) {
  return request({
    url: `/userRight/get/module-list/${usId}`,
    method: 'get'
  })
}

// 获取搜索分页数据,点击查询获取
export function getTableList(searchForm) {
  return request({
    url: '/userRight/get/page',
    method: 'get',
    params: searchForm
  })
}

// 增加权限提交，点击 添加>提交
export function addQuanxian(addForm) {
  return request({
    url: '/userRight/add',
    method: 'post',
    // params: addForm,
    data: addForm
  })
}

// 增加权限数据回显，？？
export function addHuixian(usId) {
  return request({
    url: `/userRight/get/module-menue/${usId}`,
    method: 'get'

  })
}

// 根据id获取获取对应权限相关数据,点击修改获取
export function getEditIdData(urId) {
  return request({
    url: `/userRight/get/${urId}`,
    method: 'get'

  })
}

// 根据权限带的usid查出该系统信息

// 修改权限操作,点击修改>提交
export function editQuanxian(editForm) {
  return request({
    url: '/userRight/update',
    method: 'put',
    data: editForm
  })
}

// 功能修改
export function submitGongnengData(urId, uraIdList) {
  return request({
    url: `/userRight/update/actions/${urId}`,
    method: 'put',
    data: uraIdList
  })
}

// 功能修改数据回显
export function getGongnengData(urId) {
  return request({
    url: `/userRight/get/action-list/${urId}`,
    method: 'get'

  })
}

// 根据Id删除权限，点击删除
export function deleteUrIdData(urId) {
  return request({
    url: `/userRight/delete/${urId}`,
    method: 'delete'

  })
}

// 获取菜单树
export function getTree(usId) {
  return request({
    url: `/usermenu/get/tree/${usId}`,
    method: 'get'

  })
}

